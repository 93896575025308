import React, { useState } from 'react';
import './DonationForm.css';

const DonationForm = () => {
  const [formData, setFormData] = useState({
    donationAmount: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    pincode: '',
    pan: '',
    purpose: '',
  });

  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validate form on submission
  const validateForm = () => {
    const newErrors = {};

    if (!formData.donationAmount) newErrors.donationAmount = 'Donation amount is required';
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.pincode) newErrors.pincode = 'Pincode/ZIP is required';
    if (!formData.pan) newErrors.pan = 'PAN number is required';
    if (!formData.purpose) newErrors.purpose = 'Purpose of donation is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (validateForm()) {
      // alert('Form submitted successfully!');
      // Proceed with further actions, like API calls

      try {
        const response = await fetch("http://localhost:5000/api/donation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            alert("Donation recorded successfully! Thank you for your support.");
            setFormData({
                donationAmount: '',
                name: '',
                email: '',
                phone: '',
                address: '',
                pincode: '',
                pan: '',
                purpose: '',
            }); // Reset the form
        } else {
            const errorData = await response.json();
            alert(`Failed to submit donation: ${errorData.message}`);
        }
    } catch (error) {
        alert("An error occurred. Please try again later.");
    }

    }
  };

  return (
    <div className="donation-form">
      <h3>Payment Details</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Donation Amount</label>
          <input
            type="text"
            name="donationAmount"
            placeholder="Enter Amount"
            value={formData.donationAmount}
            onChange={handleInputChange}
          />
          {errors.donationAmount && <small>{errors.donationAmount}</small>}
        </div>
        <div>
          <label>Name</label>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleInputChange}
          />
          {errors.name && <small>{errors.name}</small>}
        </div>
        <div>
          <label>Email</label>
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && <small>{errors.email}</small>}
        </div>
        <div>
          <label>Phone</label>
          <input
            type="tel"
            name="phone"
            placeholder="Your Phone Number"
            value={formData.phone}
            onChange={handleInputChange}
          />
          {errors.phone && <small>{errors.phone}</small>}
        </div>
        <div>
          <label>Complete Postal Address</label>
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleInputChange}
          />
          {errors.address && <small>{errors.address}</small>}
        </div>
        <div>
          <label>Pincode or ZIP</label>
          <input
            type="text"
            name="pincode"
            placeholder="Pincode/ZIP"
            value={formData.pincode}
            onChange={handleInputChange}
          />
          {errors.pincode && <small>{errors.pincode}</small>}
        </div>
        <div>
          <label>PAN Number</label>
          <input
            type="text"
            name="pan"
            placeholder="PAN Number"
            value={formData.pan}
            onChange={handleInputChange}
          />
          {errors.pan && <small>{errors.pan}</small>}
        </div>
        <div>
          <label>Purpose of Donation</label>
          <input
            type="text"
            name="purpose"
            placeholder="Purpose"
            value={formData.purpose}
            onChange={handleInputChange}
          />
          {errors.purpose && <small>{errors.purpose}</small>}
        </div>
        <button type="submit">Pay ₹{formData.donationAmount || '0.00'}</button>
      </form>
    </div>
  );
};

export default DonationForm;
