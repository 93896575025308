import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Nav.css';
import { HashLink } from 'react-router-hash-link';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../assets/images/HEADER_LOGO.png';
  
const TopContainer = () => {
  const navigate = useNavigate();
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleDonateClick = () => {
    closeMenu();
    navigate('/Donate');
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="top-container">
    <div className="newdev">
      <a href="/">
        <img src={logo} alt="Logo" className="main-logo" />
      </a>
      <Navbar.Brand href="/" className="foundation-brand">
        AARADHYA FOUNDATION <br />
        <span className="registration-number">Regd. No. E-1440(A)</span>
      </Navbar.Brand>
    </div>
      <nav className="navbar navbar-expand-lg navbar-light" ref={menuRef}>
        <div className="container-fluid">
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
            <img src={logo} alt="Sidebar-Logo" className="side-bar-logo" />

            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/" onClick={closeMenu}>HOME</a>
              </li>

              <Nav.Link as={HashLink} smooth to="/#about" className="custom-nav-link" onClick={closeMenu}>
                ABOUT US
              </Nav.Link>

              <NavDropdown title="PROJECTS">
                <NavDropdown.Item as={Link} to="/MahilaVikas" onClick={closeMenu}>
                  MAHILA VIKAS
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/gallery-detail" className="custom-nav-link" onClick={closeMenu}>
                GALLERY
              </Nav.Link>

              <NavDropdown title="OUR INSTITUTE">
                <NavDropdown.Item
                  as="a"
                  href="http://bdharneschool.aaradhyafoundation.org.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  B. D. HARNE ENGLISH MEDIUM SCHOOL & JUNIOR COLLEGE
                </NavDropdown.Item>

                {/* <NavDropdown.Item as={Link} to="http://bdharneschool.aaradhyafoundation.org.in" onClick={closeMenu}>
                  B. D. HARNE ENGLISH MEDIUM SCHOOL & JUNIOR COLLEGE
                </NavDropdown.Item> */}
              </NavDropdown>

              <Nav.Link as={Link} to="/csr-activity" className="custom-nav-link" onClick={closeMenu}>
                CSR ACTIVITY
              </Nav.Link>

              <Nav.Link as={HashLink} smooth to="/#announcementsevents" className="custom-nav-link" onClick={closeMenu}>
                NEWS
              </Nav.Link>

              <Nav.Link as={Link} to="/contact" className="custom-nav-link" onClick={closeMenu}>
                CONTACT
              </Nav.Link>
            </ul>
            
            {/* <button className="cta-btnn" onClick={handleDonateClick}>
              <Nav.Link as={Link} to="/Donate" onClick={closeMenu}>
              <strong>DONATE</strong>
              </Nav.Link>
            </button> */}

            <button className="cta-btnn" onClick={handleDonateClick}>
              DONATE
            </button>
          </div>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
              aria-controls="navbarNav"
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          
      </nav>
    </div>
  );
};

export default TopContainer;
