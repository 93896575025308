import React from 'react';
import aboutImage from '../assets/images/center.jpg';
import aboutImage1 from '../assets/images/3188.jpg';

const AboutUs = () => {
  return (
    
    <section className="about-us" id="about">
    <div className="mainabout">
    <h2>ABOUT US</h2>
      <div className="about-us-content">
        <div className="image-content">
          <img src={aboutImage} alt="About Us" />
          <img src={aboutImage1} alt="About Us" />
        </div>

        <div className="text-content">
          <p>
          Established in 2020, the AF (Aaradhya Foundation) is the social development arm governed by the ideology that every life is important and must be given fair opportunities to make the best out of it. The Foundation takes conscious steps to support and empower communities, primarily located around its plants. The Foundation is working relentlessly to tackle the issue related to health & nutrition, facilitating to make learning more effective and meaningful, empowering the youth through employable skill programs, through long-term watershed development programs.
          </p>
          <p>
          Its educational interventions have positively so many under-privileged children in rural locations while it’s Agriculture, Women & Rural initiatives have created new livelihood sources for so many farmers and rural women. The Foundation has provided access to sanitation in rural villages. It has also helped so many needy individuals.
          </p>
          <p>
          One of the guiding principles of Aaradhya Foundation is <strong>‘Transparency’</strong>. Donations are an ideal means of carrying forward a good cause. A donation can also make a significant impact on the lives of people in need. Aaradhya Foundation has been able to earn the trust of donors.
          </p>
        </div>
        
      </div>

      </div>
    </section>

  );
};

export default AboutUs;
