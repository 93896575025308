// AwardsAccomplishments.js
import React from 'react';

import image1 from '../assets/images/awards1.png';
import image2 from '../assets/images/awards1.png';
import image3 from '../assets/images/awards1.png';
import image4 from '../assets/images/awards1.png';

const awards = [
  {
    title: 'Gold Award',
    institution: 'BRANDON HALL',
    description: 'Our Classes has won TWO GOLD AWARDS at Brandon Hall.',
    image: image1,
  },
  {
    title: 'Most Promising Brand',
    institution: 'THE ECONOMIC TIMES',
    description: 'We recognised as The Economic Times\' Most Promising Academy 2022.',
    image: image2,
  },
  {
    title: 'Education Company of the Year',
    institution: 'MOSAIC DIGITAL',
    description: 'We won Education Company of the Year by VC Circle in Feb 2022.',
    image: image3,
  },
  {
    title: 'Students Choice',
    institution: 'AMBITION',
    description: 'Rated Top #3 in Coaching all over Maharashtra.',
    image: image4,
  },
  // Add more awards here
];

const AwardsAccomplishments = () => {
  return (
    <section className="awardsaccomplishments-section">
      <h2 className="awardsaccomplishments-title">ACHIEVEMENTS</h2>
      <p className="awardsaccomplishments-subtitle">
        As India’s largest higher education company, we have been honoured with several awards.
      </p>
      <div className="awardsaccomplishments-slider">
        <div className="awardsaccomplishments-slide-track">
          {awards.map((award, index) => (
            <div key={index} className="awardaccomplishments-card">
              <img src={award.image} alt={award.title} className="awardaccomplishments-image" />
              <h3>{award.institution}</h3>
              <h4>{award.title}</h4>
              <p>{award.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AwardsAccomplishments;

