import React from "react";
import './CSRActivities.css';
import bgImage from '../assets/images/csr.jpg';
import healthImage from '../assets/images/website_Health.jpg';
import empoweringImage from '../assets/images/website_Women&Childrens.jpg';
import educationImage from '../assets/images/website_Education.jpg';
import ruralImage from '../assets/images/website_Infrastructure.jpg';
import vocationalImage from '../assets/images/website_VocationalT.jpg';
import environmentImage from '../assets/images/website_SaveE.jpg';
import therapyImage from '../assets/images/website_AlternativeT.jpg';
import researchImage from '../assets/images/website_ResearchH.jpg';


const CSRActivities = () => {
  const activities = [
    { title: "Health", image: healthImage },
    { title: "Empowering Women & Children", image: empoweringImage },
    { title: "Education", image: educationImage },
    { title: "Rural Infrastructure", image: ruralImage },
    { title: "Vocational Training", image: vocationalImage },
    { title: "Save Environment", image: environmentImage },
    { title: "Alternative Therapy", image: therapyImage },
    { title: "Research in Health Care", image: researchImage },
  ];

  return (
    <section className="csr-activities">
      {/* Top Image Section */}
      <div className="csr-top-image" style={{ backgroundImage: `url(${bgImage})` }}>
        <h1>Corporate Social Responsibility</h1>
      </div>

      {/* Corporate Engagement Section */}
      <div className="corporate-section">
        <h3>CSR Activities:</h3>
        <p>
          The role of corporate in meeting socio-economic and environmental issues has never been more important.
          As corporate citizens, the impacts of business activity – through their supply chain, employment of
          local people, and the products and services that they provide – have come into the mainstream. The
          spotlight has come through the introduction of Section 135 and Schedule VII of the Companies Act 2013,
          mandating companies to dedicate spending to Corporate Social Responsibility activity.
        </p>
        <p>
          At AARADHYA FOUNDATION, we value corporate engagement in our programmes and recognize the role that
          business can, and does play in meeting societal needs. Our inclusive approach to partnerships means
          that we value input from the private sector in ensuring that our activities are meeting all our
          stakeholder needs. Furthermore, we also understand the business benefits derived for a company from being associated with meaningful programmes that drive real and tangible change in the community: employee recruitment and retention, competitive advantage and best practice initiatives to promote health and safety in their operations, all contribute to public and stakeholder perceptions of a business, and its operations.
        </p>
        <h3>CSR Strategy:</h3>
        <ul>
          <li>Bespoke strategy development, materiality-led and aligned with corporate objectives and positioning.</li>
          <li>Integration of CSR and governance policies to enhance sustainable growth, reduce reputation risk and to generate value.</li>
          <li>Liaison with the investment community to ensure focused dialogue on CSR and CG commitments and achievements.</li>
          <li>Employee Engagement: Attract employees and increase employee retention.</li>
          <li>Implementing Programs and Projects: Carry out different projects and initiatives with a holistic approach towards Corporate Social Responsibility leveraging your organizations capabilities and strategic resources of the partnership to address the sustainability challenges faced by the underprivileged communities.</li>
          <li>Planning, Monitoring and Evaluation: For continuous monitoring and evaluation process to see the direction of the program, Reporting strategy and report preparation, including Annual Reports, sustainability reports, specific issues reports and on-line communications, setting up systems to measure, monitor, benchmark and report data – internally and externally.</li>
        </ul>
      </div>

      {/* Areas We Can Work On */}
      <h2> We Can Work in the Following Areas </h2>

      <div className="activity-slider">
        <div className="activity-track">
          {activities.map((activity, index) => (
            <div key={index} className="activity-card">
              <img src={activity.image} alt={activity.title} className="activity-image" />
              <div className="activity-info">
                <h6>{activity.title}</h6>
              </div>
            </div>
          ))}
         
          
          {activities.map((activity, index) => (
            <div key={`duplicate-${index}`} className="activity-card">
                <img src={activity.image} alt={activity.title} className="activity-image" />
                <div className="activity-info">
                    <h6>{activity.title}</h6>
                </div>
            </div>
          ))}
          {activities.map((activity, index) => (
            <div key={`duplicate-${index}`} className="activity-card">
                <img src={activity.image} alt={activity.title} className="activity-image" />
                <div className="activity-info">
                    <h6>{activity.title}</h6>
                </div>
            </div>
          ))}
          {activities.map((activity, index) => (
            <div key={`duplicate-${index}`} className="activity-card">
                <img src={activity.image} alt={activity.title} className="activity-image" />
                <div className="activity-info">
                    <h6>{activity.title}</h6>
                </div>
            </div>
          ))}
          {activities.map((activity, index) => (
            <div key={`duplicate-${index}`} className="activity-card">
                <img src={activity.image} alt={activity.title} className="activity-image" />
                <div className="activity-info">
                    <h6>{activity.title}</h6>
                </div>
            </div>
          ))}
          {activities.map((activity, index) => (
            <div key={`duplicate-${index}`} className="activity-card">
                <img src={activity.image} alt={activity.title} className="activity-image" />
                <div className="activity-info">
                    <h6>{activity.title}</h6>
                </div>
            </div>
          ))}
          {activities.map((activity, index) => (
            <div key={`duplicate-${index}`} className="activity-card">
                <img src={activity.image} alt={activity.title} className="activity-image" />
                <div className="activity-info">
                    <h6>{activity.title}</h6>
                </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};


export default CSRActivities;
