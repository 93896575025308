import React from 'react';
import './PrivacyPolicy.css';

const TermsAndConditions = () => {
  return (
    <div className="policy-container">
      <h1>Terms & Conditions</h1>
      <p>By using the <strong>Aaradhya Foundation</strong> website, you agree to the following Terms & Conditions.</p>
      <h2>Use of Website</h2>
      <ul>
        <li><strong>Eligibility:</strong> The website is available to all individuals who can form legally binding contracts under applicable law.</li>
        <li><strong>User Obligations:</strong> You agree to provide accurate information when making a donation or filling out any forms on the website.</li>
        <li><strong>Content Ownership:</strong> All content on this website is the intellectual property of <strong>Aaradhya Foundation</strong>.</li>
        <li><strong>Prohibited Activities:</strong> You are prohibited from using the website for unlawful activities.</li>
      </ul>
      <h2>Donations</h2>
      <ul>
        <li><strong>Purpose of Donations:</strong> All donations are used to support our mission.</li>
        <li><strong>Payment Methods:</strong> We accept donations via secure payment methods.</li>
      </ul>
      <h2>Termination of Access</h2>
      <p>We reserve the right to suspend or terminate access for users who violate these Terms.</p>
    </div>
  );
};

export default TermsAndConditions;
