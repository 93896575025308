import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import galleryImages from "./GalleryData"; // Import gallery images
import covidImages from "./CovidData";
import eyeCheckupImages from "./EyeCheckupData";

function ControlledCarousel({ images, activeIndex, onSelect }) {
  return (
    <Carousel activeIndex={activeIndex} onSelect={onSelect}>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <img src={image.src} alt={`Slide ${index + 1}`} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

const GallerySection = ({ title, description, images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleCarouselSelect = (selectedIndex) => {
    setSelectedImageIndex(selectedIndex);
  };

  if (selectedImageIndex !== null) {
    return (
      <div className="carousel-overlay">
        <div className="carousel-container">
          <ControlledCarousel
            images={images}
            activeIndex={selectedImageIndex}
            onSelect={handleCarouselSelect}
          />
          <button className="close-carousel" onClick={() => setSelectedImageIndex(null)}>X</button>
        </div>
      </div>
    );
  }

  return (
    <div className="gallery-detail-section">
      <h3>{title}</h3>
      <p>{description}</p>
      <div className="gallery-detail-images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

const GalleryDetail = () => {
  return (
    <>
      <GallerySection
        title="PLANTATION"
        description="Planting trees helps the environment to be better. It purifies the air, Saves water, helps in climate control, protects soil and benefits the overall environment in many other ways. The purpose of tree plantation is to save the endangered environment and to beautify your life. Trees are a precious gift of nature. They are known as good friends of humans. Planting trees is very important for us because they give us clean air to breathe. Produce food and help manage water by reducing floods & droughts. Trees cool our surroundings, provide homes for wildlife, and maintain soil health."
        images={galleryImages}
      />
      <GallerySection
        title="COVID-19 PANDEMIC HELP"
        description="More than a billion slum dwellers worldwide were at severe risk of the effects of Covid-19, suffering from a lack of adequate housing, running water in homes, shared toilets, little or no waste management systems, crowded public transport and limited access. For formal health facilities. The first increase in global poverty since 1998. Lost income, limited social protection & rising prices meant that even those who were previously secure were at risk of poverty and starvation."
        images={covidImages}
      />
      <GallerySection
        title="FREE EYE CHECK-UP & SURGERIES"
        description="An eye exam is a non-invasive procedure that evaluates your vision, eye health, and eye condition. An eye examination helps in early detection of problems and vision loss. They can also help detect other health conditions, such as aneurysm, brain tumors, cancer, and heart disease. Just as an annual exam can detect conditions that may not cause noticeable symptoms, an eye exam can detect health and eye problems that you may not see or feel. People who are at high risk for eye disease or vision problems may need more frequent eye exams. You may need more frequent eye examinations if: You are over 60."
        images={eyeCheckupImages}
      />
    </>
  );
};

export default GalleryDetail;
