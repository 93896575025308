// GalleryData.js
const galleryImages = [
    {
      src: require("../assets/images/Gallery_Plantation_1.jpg"),
      alt: "Plantation image",
    },
    {
      src: require("../assets/images/Gallery_Plantation_2.jpg"),
      alt: "Plantation image",
    },
   
    {
      src: require("../assets/images/Gallery_Plantation_3.jpg"),
      alt: "Plantation image",
    },

    
  ];
  
  export default galleryImages;
  
  